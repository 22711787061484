/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@import "fonts.css";

.textloop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
}

@-webkit-keyframes slideBg {
  0% {
    background-image: url(./Assets/homebg.jpg);
  }
  20% {
    background-image: url(./Assets/homebg2.jpg);
  }
  40% {
    background-image: url(./Assets/bg.jpg);
  }
  60% {
    background-image: url(./Assets/homebg3.jpg);
  }
  80% {
    background-image: url(./Assets/homebg4.jpg);
  }
  100% {
    background-image: url(./Assets/homebg.jpg);
  }
}

@-moz-keyframes slideBg {
  0% {
    background-image: url(./Assets/homebg.jpg);
  }
  20% {
    background-image: url(./Assets/homebg2.jpg);
  }
  40% {
    background-image: url(./Assets/bg.jpg);
  }
  60% {
    background-image: url(./Assets/homebg3.jpg);
  }
  80% {
    background-image: url(./Assets/homebg4.jpg);
  }
  100% {
    background-image: url(./Assets/homebg.jpg);
  }
}

@-o-keyframes slideBg {
  0% {
    background-image: url(./Assets/homebg.jpg);
  }
  20% {
    background-image: url(./Assets/homebg2.jpg);
  }
  40% {
    background-image: url(./Assets/bg.jpg);
  }
  60% {
    background-image: url(./Assets/homebg3.jpg);
  }
  80% {
    background-image: url(./Assets/homebg4.jpg);
  }
  100% {
    background-image: url(./Assets/homebg.jpg);
  }
}

@keyframes slideBg {
  0% {
    background-image: url(./Assets/homebg.jpg);
  }
  20% {
    background-image: url(./Assets/homebg2.jpg);
  }
  40% {
    background-image: url(./Assets/bg.jpg);
  }
  60% {
    background-image: url(./Assets/homebg3.jpg);
  }
  80% {
    background-image: url(./Assets/homebg4.jpg);
  }
  100% {
    background-image: url(./Assets/homebg.jpg);
  }
}

.slideshowbg {
  width: 100vw;
  height: 100vh;
  background-image: url(./Assets/homebg3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.textloop {
  margin-bottom: 50vh;
  color: rgb(255, 255, 251);
  font-weight: 5000;
  font-size: 40px;
}

.parentvid1 {
  width: 60%;
  margin: auto;
}

.parentvid11 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.parentvid11 big {
  width: 90%;
}

.textvid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
}

.video {
  width: 80vh;
  -webkit-border-radius: 2px 15px 15px 15px;
  -moz-border-radius: 2px 15px 15px 15px;
  border-radius: 2px 15px 15px 15px;
  height: 45vh;
  outline: none;
}

.home-footerRight {
  background-color: rgb(74, 74, 74);
  height: 10vh;
  display: table-cell;
  vertical-align: bottom;
  width: 100vw;
}

.home-footerLeft {
  background-color: rgb(74, 74, 74);
  height: 10vh;
  display: table-cell;
  vertical-align: bottom;
  width: 100vw;
}

.contactus {
  width: 97%;

  text-align: right;
  margin-right: 3%;
}

.terms {
  width: 50%;
}

.socialmedia {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.app-form-button {
  margin: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #ffdc00;
  -webkit-box-shadow: 0px 0px 0px transparent;
  -moz-box-shadow: 0px 0px 0px transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  -webkit-transition: all ease-in-out 3s forwards;
  -o-transition: all ease-in-out 3s forwards;
  -moz-transition: all ease-in-out 3s forwards;
  transition: all ease-in-out 3s forwards;
}

.app-form-button:hover {
  background-color: #ffc800;
}

.app-form-button:focus {
  -webkit-box-shadow: 0 0 0 1px rgba(211, 50, 50, 0.3),
    0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(211, 50, 50, 0.3);
  -moz-box-shadow: 0 0 0 1px rgba(211, 50, 50, 0.3),
    0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(211, 50, 50, 0.3);
  box-shadow: 0 0 0 1px rgba(211, 50, 50, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(211, 50, 50, 0.3);
  outline: none;
  z-index: 9;
}

.hfbr {
  display: none;
}

* {
  font-family: "Poppins-Regular";
}

@media screen and (max-width: 935px) {
  .textvid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
  }
  .video {
    width: 60vh;
    -webkit-border-radius: 2px 15px 15px 15px;
    -moz-border-radius: 2px 15px 15px 15px;
    border-radius: 2px 15px 15px 15px;
    height: 33.7500000001vh;
  }
}

@media screen and (max-height: 1112px) {
  .video {
    -webkit-border-radius: 2px 15px 15px 15px;
    -moz-border-radius: 2px 15px 15px 15px;
    border-radius: 2px 15px 15px 15px;
    width: 300px !important;
    height: 168.75px !important;
  }
}

@media screen and (max-width: 679px) {
  .video {
    width: 40vh;
    -webkit-border-radius: 2px 15px 15px 15px;
    -moz-border-radius: 2px 15px 15px 15px;
    border-radius: 2px 15px 15px 15px;
    height: 22.5vh;
  }
  .WWA22 {
    font-size: 60px;
  }
  .home-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    background-color: rgb(74, 74, 74);
  }
  .hfbr {
    display: inline-block;
  }
  .contactus {
    width: 90%;
    margin: auto;
  }
  .textloop {
    font-size: 30px;
  }
  .WWA {
    font-size: 60px;
  }
}
.contactus {
  margin: auto;
}
@media screen and (max-width: 469px) {
  .video {
    width: 26.6666666667vh;
    -webkit-border-radius: 2px 15px 15px 15px;
    -moz-border-radius: 2px 15px 15px 15px;
    border-radius: 2px 15px 15px 15px;
    height: 15vh;
  }
  .textloop {
    font-size: 20px;
  }
  .aaa {
    font-size: 10px;
  }
}
.fancy-button {
  display: inline-block;
  margin: 30px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  width: 100%;
} /* bg shortcodes */
.bg-gradient1 span,
.bg-gradient1:before {
  background: #52a0fd;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#52a0fd),
    color-stop(80%, #00e2fa),
    to(#00e2fa)
  );
  background: -o-linear-gradient(left, #52a0fd 0%, #00e2fa 80%, #00e2fa 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#52a0fd),
    color-stop(80%, #00e2fa),
    to(#00e2fa)
  );
  background: -webkit-linear-gradient(
    left,
    #52a0fd 0%,
    #00e2fa 80%,
    #00e2fa 100%
  );
  background: -moz-linear-gradient(left, #52a0fd 0%, #00e2fa 80%, #00e2fa 100%);
  background: linear-gradient(to right, #52a0fd 0%, #00e2fa 80%, #00e2fa 100%);
}
.bg-gradient2 span,
.bg-gradient2:before {
  background: #44ea76;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#44ea76),
    color-stop(80%, #39fad7),
    to(#39fad7)
  );
  background: -o-linear-gradient(left, #44ea76 0%, #39fad7 80%, #39fad7 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#44ea76),
    color-stop(80%, #39fad7),
    to(#39fad7)
  );
  background: -webkit-linear-gradient(
    left,
    #44ea76 0%,
    #39fad7 80%,
    #39fad7 100%
  );
  background: -moz-linear-gradient(left, #44ea76 0%, #39fad7 80%, #39fad7 100%);
  background: linear-gradient(to right, #44ea76 0%, #39fad7 80%, #39fad7 100%);
}
.bg-gradient3 span,
.bg-gradient3:before {
  background: #fa6c9f;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fa6c9f),
    color-stop(80%, #ffe140),
    to(#ffe140)
  );
  background: -o-linear-gradient(left, #fa6c9f 0%, #ffe140 80%, #ffe140 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fa6c9f),
    color-stop(80%, #ffe140),
    to(#ffe140)
  );
  background: -webkit-linear-gradient(
    left,
    #fa6c9f 0%,
    #ffe140 80%,
    #ffe140 100%
  );
  background: -moz-linear-gradient(left, #fa6c9f 0%, #ffe140 80%, #ffe140 100%);
  background: linear-gradient(to right, #fa6c9f 0%, #ffe140 80%, #ffe140 100%);
}
/* General */
.wrapper {
  margin: 5% auto;
  text-align: center;
  -webkit-transform-style: perserve-3d;
  -moz-transform-style: perserve-3d;
  transform-style: perserve-3d;
  perspecive: 800px;
}
a {
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
}
.fancy-button:before {
  content: "";
  display: inline-block;
  height: 40px;
  position: absolute;
  bottom: -5px;
  left: 30px;
  right: 30px;
  z-index: -1;
  -webkit-border-radius: 30em;
  -moz-border-radius: 30em;
  border-radius: 30em;
  -webkit-filter: blur(20px) brightness(0.95);
  filter: blur(20px) brightness(0.95);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.fancy-button i {
  margin-top: -1px;
  margin-right: 20px;
  font-size: 1.265em;
  vertical-align: middle;
}
.fancy-button span {
  display: inline-block;
  padding: 18px 60px;
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  position: relative;
  z-index: 2;
  will-change: transform, filter;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.fancy-button:focus,
.fancy-button:active {
  color: #fff;
}
.fancy-button:hover {
  color: #fff;
}
.fancy-button:hover span {
  -webkit-filter: brightness(1.05) contrast(1.05);
  filter: brightness(1.05) contrast(1.05);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}
.fancy-button:hover:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
}
.fancy-button.pop-onhover:before {
  opacity: 0;
  bottom: 10px;
}
.fancy-button.pop-onhover:hover:before {
  bottom: -7px;
  opacity: 1;
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
.fancy-button.pop-onhover:hover span {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  transform: scale(1.04);
}
.fancy-button.pop-onhover:hover:active span {
  -webkit-filter: brightness(1) contrast(1);
  filter: brightness(1) contrast(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.fancy-button.pop-onhover:hover:active:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.burger,
.burger1 {
  width: 100% !important;
  height: 100% !important;
  z-index: 1 !important;
  position: fixed !important;
  background-color: rgb(74, 74, 74) !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -moz-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: -moz-box !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  -moz-box-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  -webkit-align-items: center !important;
  -moz-box-align: center !important;
  align-items: center !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  -webkit-flex-direction: column !important;
  -moz-box-orient: vertical !important;
  -moz-box-direction: normal !important;
  flex-direction: column !important;
}

.burgermenu {
  z-index: 999999999999999999999 !important;
}

.WWA {
  margin-top: 20em;
}

.textloop {
  position: absolute;
  bottom: -100px;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 0;
}

@media screen and (max-height: 1006px) {
  .WWA {
    margin-top: 15em;
  }
}
/* @media screen and (max-height: 672px) {
  .WWA {
    margin-top: 10em;
  }
} */

.Hheading1b,
.Hheading2b {
  font-size: 27px !important;
}

@media screen and (max-width: 677px) {
  .parentvid1 {
    width: 90%;
  }
  /* .WWA {
    margin-top: 25em;
    font-size: 30px;
  } */
  .textvid {
    font-size: 20px;
  }
}
@media screen and (max-width: 419px) {
  .fancy-button span {
    font-size: 15px !important;
  }
  header {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 379px) {
  .logoimg2 {
    display: none !important;
  }
}
