/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.CL {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="url"],
.Autocomplete,
select,
textarea,
input[type="password"] {
  width: 20%;
  padding: 12px;
  border: 1px solid #ccc;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="submit"],
input[type="button"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.noDonate:hover {
  background-color: #c63939 !important;
  color: white;
  padding: 12px 20px;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover,
input[type="button"]:hover {
  background-color: #45a049;
}
.container {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.logoimgCL {
  position: fixed;
  left: 0px;
  top: 15px;
}

.formCL {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

@media screen and (max-height: 739px) {
  .titleCol {
    display: none;
  }
}

@media screen and (max-height: 513px) {
  .formCL {
    margin-top: 80px;
  }
}

@media screen and (max-height: 464px) {
  .formCL {
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -o-transform: scale(0.6);
    transform: scale(0.6);
  }
  .logoimg2 {
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -o-transform: scale(0.6);
    transform: scale(0.6);
  }
}
