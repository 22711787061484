/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.titleQR {
  font-size: 50px;
  text-align: center;
}

.QRCode {
  width: 256px;
  height: 256px;
}

@media screen and (max-width: 570px) {
  .titleQR {
    font-size: 40px;
  }

  .QRCode {
    width: 128px;
    height: 128px;
  }

  .sendtxtext {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }

  .sendbtnbutton {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }

  .sendwrngwrong {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@media screen and (max-width: 512px) {
  .sendtxtext {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 436px) {
  .titleQR {
    font-size: 30px;
  }
}

@media screen and (max-width: 661px) {
  .QRCODE input {
    width: 100% !important;
  }
}

#CardShareOrForMyself:hover {
  box-shadow: 4px 3px 5px rgb(215, 215, 215);
}

#CardShareOrForMyself {
  transition: ease 0.1s all;
}
.CardShareOrForMyself1 {
  margin-right: 30px;
}

.CARDSHARBTN {
  width: 20%;
}

@media screen and (max-width: 906px) {
  .CARDSHARBTN {
    width: 70%;
  }
}

@media screen and (max-width: 476px) {
  .cardcontainer {
    flex-direction: column;
  }
  .CardShareOrForMyself1 {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
