/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.abouttxt {
  text-align: center;
  width: 70%;
  margin: auto;
}

.titleA {
  text-align: center;
}
.RaghavVaibhavHerugu {
  width: 300px;
  margin: auto 30px !important;
}

@media screen and (max-width: 1093px) {
  .divflex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
  }
}
