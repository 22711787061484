/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.BLogin {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  overflow: auto;
}

.spacer {
  width: 100%;
  height: 35vh;
}

.titleB {
  font-size: 50px;

  margin-top: 50vh !important;
}

@media screen and (max-width: 628px) {
  .titleB {
    font-size: 30px;
  }
}
@media screen and (max-width: 520px) {
  .titleB {
    font-size: 20px;
  }
}
@media screen and (max-height: 1048px) {
  .titleB {
    margin-top: 70vh !important;
  }
}
@media screen and (max-height: 899px) {
  .titleB {
    margin-top: 100vh !important;
  }
}

.date-field {
  width: 30px;
  text-align: center;
}
.date-field--year {
  width: 60px;
}
.single-date-field {
  width: 120px;
}
.dobwrap {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transform: translate(-50%);
  transform: translate(-50%);
}
.field-inline-block {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  width: 100;
}
.field-inline-block label {
  text-align: center;
}

.mainBR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  margin: 0;
}

.formBR a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.oo {
  width: 50%;
  margin: auto;
}
.fancy-button {
  margin: auto;
}

.formBR label {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transform: translate(-50%);
  transform: translate(-50%);
}

#formBR1,
#titleB1 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#titleB1 {
  position: absolute;
  left: 50%;
  top: -5%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.termsforstripe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transform: translate(-50%);
  transform: translate(-50%);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

@media screen and (max-width: 654px) {
  .termsforstripe {
    font-size: xx-small;
  }
}

@media screen and (max-width: 554px) {
  .termsforstripe {
    font-size: 7px;
  }
}

@media screen and (max-width: 428px) {
  .termsforstripe {
    font-size: 5px;
  }
}

@media screen and (max-width: 582px) {
  .saysome {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 542px) {
  .formBR {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@media screen and (max-width: 444px) {
  .formBR {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
}
@media screen and (max-width: 546px) {
  .titleB {
    display: none;
  }
  .formBR {
    margin-top: 100vh !important;
  }
}
@media screen and (max-width: 354px) {
  .formBR {
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -o-transform: scale(0.6);
    transform: scale(0.6);
    margin-top: 49vh !important;
  }
  .titleB {
    display: none;
  }
}
@media screen and (max-height: 758px) {
  .titleB {
    margin-top: 150vh !important;
  }
}
@media screen and (max-height: 587px) {
  .titleB {
    margin-top: 180vh !important;
  }
}
@media screen and (max-height: 506px) {
  .titleB {
    margin-top: 200vh !important;
  }
}

/* @media screen and (max-height: 754px) {
  .formBR {
    margin-top: 400px;
  }
} */
/* @media screen and (max-height: 670px) {
  .formBR {
    margin-top: 500px;
  }
}
@media screen and (max-height: 557px) {
  .formBR {
    margin-top: 600px;
  }
}
@media screen and (max-height: 433px) {
  .formBR {
    margin-top: 700px;
  }
} */

@media screen and (max-height: 412px) {
  .logoimg2 {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}

.link {
  /* RESET */
  text-decoration: none;
  padding: 0px 2px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #333;
  vertical-align: bottom;
  -webkit-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

body {
  -webkit-font-smoothing: antialiased;
}

.link::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-webkit-calc(100% - 2px));
  -ms-transform: translateY(calc(100% - 2px));
  -moz-transform: translateY(-moz-calc(100% - 2px));
  -o-transform: translateY(calc(100% - 2px));
  transform: translateY(calc(100% - 2px));
  width: 100%;
  height: 100%;
  background-image: -o-linear-gradient(30deg, #64b3f4 0%, #c2e59c 100%);
  background-image: -webkit-linear-gradient(30deg, #64b3f4 0%, #c2e59c 100%);
  background-image: -moz-linear-gradient(30deg, #64b3f4 0%, #c2e59c 100%);
  background-image: linear-gradient(60deg, #64b3f4 0%, #c2e59c 100%);
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  -o-transition: transform 0.25s ease-out;
  -o-transition: -o-transform 0.25s ease-out;
  -moz-transition: transform 0.25s ease-out, -moz-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out,
    -moz-transform 0.25s ease-out, -o-transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.link:hover {
  color: rgb(69, 69, 69);
}
.link:hover::before {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  -o-transition: transform 0.25s ease-out;
  -o-transition: -o-transform 0.25s ease-out;
  -moz-transition: transform 0.25s ease-out, -moz-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out,
    -moz-transform 0.25s ease-out, -o-transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.ze {
  width: 60% !important;
}

.help {
  background-color: rgba(74, 74, 74, 0.909);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9999999999999999999999999999999;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.help label,
.help li {
  color: white;
}

.help a {
  color: white !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.f {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: auto !important;
}

@media screen and (max-width: 763px) {
  .Bdash {
    height: 50px !important;
    font-size: 15px !important;
  }
}

@media screen and (max-width: 403px) {
  .Bdash {
    height: 45px !important;
    font-size: 10px !important;
  }
}

@media screen and (max-width: 699px) {
  .hjdfs {
    top: 0 !important;
    left: 50% !important;
    transform: translate(-50%);
  }
  .hjdfsz {
    top: 10% !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}
