/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@font-face {
  font-family: "Poppins-Black";
  src: url("./Fonts/Poppins/Poppins-Black.ttf");
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("./Fonts/Poppins/Poppins-BlackItalic.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./Fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("./Fonts/Poppins/Poppins-BoldItalic.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("./Fonts/Poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("./Fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("./Fonts/Poppins/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url("./Fonts/Poppins/Poppins-ExtraLightItalic.ttf");
}

@font-face {
  font-family: "Poppins-Italic";
  src: url("./Fonts/Poppins/Poppins-Italic.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./Fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: url("./Fonts/Poppins/Poppins-LightItalic.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./Fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("./Fonts/Poppins/Poppins-MediumItalic.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./Fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./Fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("./Fonts/Poppins/Poppins-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("./Fonts/Poppins/Poppins-Thin.ttf");
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("./Fonts/Poppins/Poppins-ThinItalic.ttf");
}
