/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.Hheading1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-family: "Poppins-Light";
  color: white;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-flex: 0.2;
  -ms-flex: 0.2;
  -webkit-flex: 0.2;
  -moz-box-flex: 0.2;
  flex: 0.2;
}

.Hheading2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-family: "Poppins-Light";
  color: white;

  cursor: pointer;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
  float: left;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  justify-content: flex-end;
}

.logoimg,
.logoimg2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-family: "Poppins-SemiBold";
  color: white;

  cursor: pointer;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
  float: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  justify-content: flex-start;
}

.Hheading span:hover,
.Hheading1 span:hover,
.Hheading2 span:hover {
  color: rgb(183, 183, 183);
}

.Hheading,
.Hheading1,
.Hheading2 {
  width: auto;
  height: auto;
  cursor: pointer;
}

.Home-Header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  width: 100%;
  height: 10vh;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  background-color: rgb(74, 74, 74);
  position: fixed;
  padding-top: 0px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  z-index: 3;
  top: 0px;
  margin-top: 0px;
}

.HH {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  background-color: rgb(74, 74, 74);
  z-index: 3;
}

.logoimg2 {
  display: none;
}

@media screen and (max-width: 1483px) {
  .Hheading1 {
    -webkit-box-flex: 0.5;
    -ms-flex: 0.5;
    -webkit-flex: 0.5;
    -moz-box-flex: 0.5;
    flex: 0.5;
  }
}

@media screen and (max-width: 1034px) {
  .logoimg2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    font-family: "Poppins-SemiBold";
    color: white;

    cursor: pointer;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    flex: 1;
    float: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    justify-content: flex-start;

    -webkit-transform: scale(0.7);

    -ms-transform: scale(0.7);

    -moz-transform: scale(0.7);

    -o-transform: scale(0.7);

    transform: scale(0.7);
  }

  .logoimg {
    display: none;
  }
}
@media screen and (max-width: 694px) {
  .Hheading2,
  .Hheading1 {
    display: none;
  }

  .Hheading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    font-family: "Poppins-Light";
    color: white;

    cursor: pointer;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    flex: 1;
    float: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    justify-content: flex-start;
  }
}

.Contact {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.mainC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  margin: auto;
  width: 20%;
}

.titleC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 50px;
  margin: auto;
}

.formC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  margin: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  width: 100%;
}

.ButtonC {
  background: none;
  border: none;
  color: #ea1d6f;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  float: right;
  margin: 0;
}

.ButtonC:hover {
  color: #b9134f;
}

.ButtonsC {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.inputC {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  color: rgb(50, 50, 50);
  font-size: 14px;
  outline: none;
  -webkit-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -moz-transition: border-color 0.2s;
  transition: border-color 0.2s;
}

.formCddsadsa {
  padding: 10px 10px !important;
}

@media screen and (max-width: 658px) {
  .formCL {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@media screen and (max-width: 530px) {
  .formCCL {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@media screen and (max-width: 548px) {
  .formCL {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  .titleCol {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
    margin: 0;
  }
}

@media screen and (max-width: 424px) {
  .formCL {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  .titleCol {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
    margin: 0;
  }
}

.burgermenu {
  display: none;
  height: 30px;
  width: 35px;
  z-index: 90909999;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}
.bar {
  height: 5px;
  z-index: 909099999;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  margin: 3px;
  cursor: pointer;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.burger1 {
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  background-color: rgb(71, 71, 71);
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media screen and (max-width: 450px) {
  .burger1 {
    height: 300%;
  }
  .Hheading1b,
  .Hheading2b {
    font-size: 45px;
  }
}

/* 
@media screen and (max-height: 1077px) {
  .home-footer {
    height: 1000vh;
  }
} */

@media screen and (max-width: 209px) {
  .burger1 {
    height: 325%;
  }
}

.Hheading1b,
.Hheading2b {
  color: white;
  font-size: 40px;
}

@media screen and (max-width: 694px) {
  .Hheading2,
  .Hheading1 {
    display: none;
  }

  .burgermenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
  }
  .logoimg2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    flex-direction: row-reverse;
    margin-right: 0;
    position: absolute;
    left: 100px;
  }

  .Hheading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    font-family: "Poppins-Light";
    color: white;

    cursor: pointer;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    flex: 1;
    float: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1373px) {
  .mainC {
    width: 70%;
  }
}

@media screen and (max-height: 763px) {
  .mainC {
    margin-top: 300px;
  }
}

.Contact {
  overflow: scroll !important;
}
