/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.typagraphywrap,
.content {
  word-wrap: break-word;
}

@media screen and (max-width: 642px), (max-height: 755px) {
  #Cardoo {
    height: 220px;
    overflow: scroll;
  }
  .at {
    overflow: scroll;
  }
}

#cardWrapper::-webkit-scrollbar,
#Cardoo::-webkit-scrollbar {
  display: none !important;
}

.btn {
  display: flex;
  flex-direction: row;
  z-index: 9999999999999999999999999;
}

.cardBtn {
  width: 170px;
  height: 35px;
}

#totalCardWrapper {
  border: 1.89px solid black;
  margin: 30px 0px auto;
  border-radius: 5px;
  padding: 19px;
  cursor: pointer;
}

.cardBtn {
  margin: auto;
}

.buybtncard {
  margin-right: 30px;
}

.content {
  margin: auto 0px 10px;
}

#Cardoo {
  width: 100% !important;
  height: fit-content;
  overflow: hidden;
}

.MediaCared1 {
  height: fit-content !important;
}

.CardTooltip {
  width: 500px !important;
  text-align: center;
}

@media screen and (max-width: 437px) {
  .cardBtn2 {
    width: fit-content;
  }
  .cardBtn1 {
    margin: 0 !important;
  }
}

@media screen and (max-width: 320px) {
  .btn {
    flex-direction: column;
  }
  .cardBtn {
    margin: 0 !important;
  }
}
