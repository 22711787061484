@import url(https://fonts.googleapis.com/css?family=Raleway:400,400i,700);
@import url(/Users/raghavherugu/Documents/localmainstreet/src/fonts.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@font-face {
  font-family: "Poppins-Black";
  src: url(/static/media/Poppins-Black.42cf9f08.ttf);
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url(/static/media/Poppins-BlackItalic.16dc9819.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(/static/media/Poppins-Bold.c23534ac.ttf);
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url(/static/media/Poppins-BoldItalic.5bda2710.ttf);
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url(/static/media/Poppins-ExtraBold.6b78c7ec.ttf);
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url(/static/media/Poppins-ExtraBoldItalic.e59d3264.ttf);
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url(/static/media/Poppins-ExtraLight.8ea2657f.ttf);
}

@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url(/static/media/Poppins-ExtraLightItalic.f5080ea9.ttf);
}

@font-face {
  font-family: "Poppins-Italic";
  src: url(/static/media/Poppins-Italic.89c81a3c.ttf);
}

@font-face {
  font-family: "Poppins-Light";
  src: url(/static/media/Poppins-Light.2a47a29c.ttf);
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: url(/static/media/Poppins-LightItalic.926a872e.ttf);
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(/static/media/Poppins-Medium.ba95810b.ttf);
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url(/static/media/Poppins-MediumItalic.9e911c68.ttf);
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(/static/media/Poppins-Regular.41e8dead.ttf);
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(/static/media/Poppins-SemiBold.342ba3d8.ttf);
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url(/static/media/Poppins-SemiBoldItalic.0fc816cb.ttf);
}

@font-face {
  font-family: "Poppins-Thin";
  src: url(/static/media/Poppins-Thin.c0fafa83.ttf);
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url(/static/media/Poppins-ThinItalic.272bb704.ttf);
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.textloop {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

@-webkit-keyframes slideBg {
  0% {
    background-image: url(/static/media/homebg.80e3b9a2.jpg);
  }
  20% {
    background-image: url(/static/media/homebg2.7040a9ee.jpg);
  }
  40% {
    background-image: url(/static/media/bg.207a51ec.jpg);
  }
  60% {
    background-image: url(/static/media/homebg3.286fe86a.jpg);
  }
  80% {
    background-image: url(/static/media/homebg4.c39fdfdd.jpg);
  }
  100% {
    background-image: url(/static/media/homebg.80e3b9a2.jpg);
  }
}

@keyframes slideBg {
  0% {
    background-image: url(/static/media/homebg.80e3b9a2.jpg);
  }
  20% {
    background-image: url(/static/media/homebg2.7040a9ee.jpg);
  }
  40% {
    background-image: url(/static/media/bg.207a51ec.jpg);
  }
  60% {
    background-image: url(/static/media/homebg3.286fe86a.jpg);
  }
  80% {
    background-image: url(/static/media/homebg4.c39fdfdd.jpg);
  }
  100% {
    background-image: url(/static/media/homebg.80e3b9a2.jpg);
  }
}

.slideshowbg {
  width: 100vw;
  height: 100vh;
  background-image: url(/static/media/homebg3.286fe86a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.textloop {
  margin-bottom: 50vh;
  color: rgb(255, 255, 251);
  font-weight: 5000;
  font-size: 40px;
}

.parentvid1 {
  width: 60%;
  margin: auto;
}

.parentvid11 {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.parentvid11 big {
  width: 90%;
}

.textvid {
  display: flex;
  flex-direction: row;
}

.video {
  width: 80vh;
  border-radius: 2px 15px 15px 15px;
  height: 45vh;
  outline: none;
}

.home-footerRight {
  background-color: rgb(74, 74, 74);
  height: 10vh;
  display: table-cell;
  vertical-align: bottom;
  width: 100vw;
}

.home-footerLeft {
  background-color: rgb(74, 74, 74);
  height: 10vh;
  display: table-cell;
  vertical-align: bottom;
  width: 100vw;
}

.contactus {
  width: 97%;

  text-align: right;
  margin-right: 3%;
}

.terms {
  width: 50%;
}

.socialmedia {
  display: flex;
}

.app-form-button {
  margin: 10px;
  border-radius: 5px;
  background-color: #ffdc00;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  transition: all ease-in-out 3s forwards;
}

.app-form-button:hover {
  background-color: #ffc800;
}

.app-form-button:focus {
  box-shadow: 0 0 0 1px rgba(211, 50, 50, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(211, 50, 50, 0.3);
  outline: none;
  z-index: 9;
}

.hfbr {
  display: none;
}

* {
  font-family: "Poppins-Regular";
}

@media screen and (max-width: 935px) {
  .textvid {
    flex-direction: column;
  }
  .video {
    width: 60vh;
    border-radius: 2px 15px 15px 15px;
    height: 33.7500000001vh;
  }
}

@media screen and (max-height: 1112px) {
  .video {
    border-radius: 2px 15px 15px 15px;
    width: 300px !important;
    height: 168.75px !important;
  }
}

@media screen and (max-width: 679px) {
  .video {
    width: 40vh;
    border-radius: 2px 15px 15px 15px;
    height: 22.5vh;
  }
  .WWA22 {
    font-size: 60px;
  }
  .home-footer {
    flex-direction: column;
    background-color: rgb(74, 74, 74);
  }
  .hfbr {
    display: inline-block;
  }
  .contactus {
    width: 90%;
    margin: auto;
  }
  .textloop {
    font-size: 30px;
  }
  .WWA {
    font-size: 60px;
  }
}
.contactus {
  margin: auto;
}
@media screen and (max-width: 469px) {
  .video {
    width: 26.6666666667vh;
    border-radius: 2px 15px 15px 15px;
    height: 15vh;
  }
  .textloop {
    font-size: 20px;
  }
  .aaa {
    font-size: 10px;
  }
}
.fancy-button {
  display: inline-block;
  margin: 30px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  width: 100%;
} /* bg shortcodes */
.bg-gradient1 span,
.bg-gradient1:before {
  background: #52a0fd;
  background: linear-gradient(to right, #52a0fd 0%, #00e2fa 80%, #00e2fa 100%);
}
.bg-gradient2 span,
.bg-gradient2:before {
  background: #44ea76;
  background: linear-gradient(to right, #44ea76 0%, #39fad7 80%, #39fad7 100%);
}
.bg-gradient3 span,
.bg-gradient3:before {
  background: #fa6c9f;
  background: linear-gradient(to right, #fa6c9f 0%, #ffe140 80%, #ffe140 100%);
}
/* General */
.wrapper {
  margin: 5% auto;
  text-align: center;
  -webkit-transform-style: perserve-3d;
  transform-style: perserve-3d;
  perspecive: 800px;
}
a {
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
}
.fancy-button:before {
  content: "";
  display: inline-block;
  height: 40px;
  position: absolute;
  bottom: -5px;
  left: 30px;
  right: 30px;
  z-index: -1;
  border-radius: 30em;
  -webkit-filter: blur(20px) brightness(0.95);
  filter: blur(20px) brightness(0.95);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
}
.fancy-button i {
  margin-top: -1px;
  margin-right: 20px;
  font-size: 1.265em;
  vertical-align: middle;
}
.fancy-button span {
  display: inline-block;
  padding: 18px 60px;
  border-radius: 50em;
  position: relative;
  z-index: 2;
  will-change: transform, filter;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
}
.fancy-button:focus,
.fancy-button:active {
  color: #fff;
}
.fancy-button:hover {
  color: #fff;
}
.fancy-button:hover span {
  -webkit-filter: brightness(1.05) contrast(1.05);
  filter: brightness(1.05) contrast(1.05);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.fancy-button:hover:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
}
.fancy-button.pop-onhover:before {
  opacity: 0;
  bottom: 10px;
}
.fancy-button.pop-onhover:hover:before {
  bottom: -7px;
  opacity: 1;
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
.fancy-button.pop-onhover:hover span {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.fancy-button.pop-onhover:hover:active span {
  -webkit-filter: brightness(1) contrast(1);
  filter: brightness(1) contrast(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.15s ease-out;
}
.fancy-button.pop-onhover:hover:active:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
  transition: all 0.2s ease-out;
}

.burger,
.burger1 {
  width: 100% !important;
  height: 100% !important;
  z-index: 1 !important;
  position: fixed !important;
  background-color: rgb(74, 74, 74) !important;
  transition: all 0.4s ease-in-out !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.burgermenu {
  z-index: 999999999999999999999 !important;
}

.WWA {
  margin-top: 20em;
}

.textloop {
  position: absolute;
  bottom: -100px;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 0;
}

@media screen and (max-height: 1006px) {
  .WWA {
    margin-top: 15em;
  }
}
/* @media screen and (max-height: 672px) {
  .WWA {
    margin-top: 10em;
  }
} */

.Hheading1b,
.Hheading2b {
  font-size: 27px !important;
}

@media screen and (max-width: 677px) {
  .parentvid1 {
    width: 90%;
  }
  /* .WWA {
    margin-top: 25em;
    font-size: 30px;
  } */
  .textvid {
    font-size: 20px;
  }
}
@media screen and (max-width: 419px) {
  .fancy-button span {
    font-size: 15px !important;
  }
  header {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 379px) {
  .logoimg2 {
    display: none !important;
  }
}

.mapMapBox {
  width: 68vw;
  height: 87vh;
  margin-left: 31vw;
  position: fixed;
  margin-top: 13vh;
}

.MediaCard {
  float: left;
}

.gridlist {
  width: 25vw;
  float: left;
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: static !important;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-top: 10vh;
}

.shopwrapperwrappy {
  height: 100vh;
  width: 100vw;
}

.search {
  /* margin-left: 30px;
  margin-right: 30px; */
  /* position: fixed; */
  background-color: rgb(74, 74, 74) !important;
  z-index: 1;
  padding: 30px;
  top: 10vh;
  border-radius: 0px 0px 6px 6px;
}

.khdsaid12e {
  z-index: 2 !important;
}

.Search_Businesses {
  color: white;
  font-weight: bold;
}

.Search_Businesses a {
  display: none;
  color: white;
}
/* 
@media screen and (max-width: 1518px) {
  .mapMapBox {
    width: 58vw;
    margin-left: 41vw;
  }
  .gridlist {
    width: 40vw;
  }
  .search {
    width: 25vw;
    margin: auto;
  }
}

@media screen and (max-width: 1224px) {
  .mapMapBox {
    width: 48vw;
    margin-left: 51vw;
  }
  .gridlist {
    width: 50vw;
  }
  .search {
    width: 25vw;
    margin: auto;
  }
}
@media screen and (max-width: 953px) {
  #cardWrapper {
    width: 80% !important;
  }
}

@media screen and (max-width: 832px) {
  .mapMapBox {
    display: none !important;
  }
  #cardWrapper {
    width: 90% !important;
  }
  .gridlist {
    width: 100vw;
  }
  .Search_Businesses a {
    display: flex;
    width: fit-content;
  }
  .search {
    width: 92vw;
    left: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 442px) {
  #cardWrapper {
    width: 80% !important;
  }
} */

@media screen and (max-width: 1605px) {
  .mapMapBox {
    width: 58vw;
    margin-left: 41vw;
  }
  .gridlist {
    width: 35vw;
  }
}

@media screen and (max-width: 1151px) {
  .mapMapBox {
    width: 48vw;
    margin-left: 51vw;
  }
  .gridlist {
    width: 45vw;
  }
}

@media screen and (max-width: 889px) {
  .mapMapBox {
    display: none;
  }
  .gridlist {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 463px) {
  .gridlist {
    width: calc(100% - 40px);
    margin-left: 20px;
  }
}

@media screen and (max-width: 437px) {
  .search-main {
    flex-direction: column !important;
  }
  .search-main input {
    border-radius: 6px !important;
  }
  .searchSubmit {
    width: unset !important;
  }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.typagraphywrap,
.content {
  word-wrap: break-word;
}

@media screen and (max-width: 642px), (max-height: 755px) {
  #Cardoo {
    height: 220px;
    overflow: scroll;
  }
  .at {
    overflow: scroll;
  }
}

#cardWrapper::-webkit-scrollbar,
#Cardoo::-webkit-scrollbar {
  display: none !important;
}

.btn {
  display: flex;
  flex-direction: row;
  z-index: 9999999999999999999999999;
}

.cardBtn {
  width: 170px;
  height: 35px;
}

#totalCardWrapper {
  border: 1.89px solid black;
  margin: 30px 0px auto;
  border-radius: 5px;
  padding: 19px;
  cursor: pointer;
}

.cardBtn {
  margin: auto;
}

.buybtncard {
  margin-right: 30px;
}

.content {
  margin: auto 0px 10px;
}

#Cardoo {
  width: 100% !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
}

.MediaCared1 {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.CardTooltip {
  width: 500px !important;
  text-align: center;
}

@media screen and (max-width: 437px) {
  .cardBtn2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .cardBtn1 {
    margin: 0 !important;
  }
}

@media screen and (max-width: 320px) {
  .btn {
    flex-direction: column;
  }
  .cardBtn {
    margin: 0 !important;
  }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.loaderwrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99999999999999999999999999999999999999999;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.end {
  display: none;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.abouttxt {
  text-align: center;
  width: 70%;
  margin: auto;
}

.titleA {
  text-align: center;
}
.RaghavVaibhavHerugu {
  width: 300px;
  margin: auto 30px !important;
}

@media screen and (max-width: 1093px) {
  .divflex {
    flex-direction: column;
  }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.Hheading1 {
  display: flex;
  font-family: "Poppins-Light";
  color: white;
  justify-content: center;
  cursor: pointer;
  flex: 0.2 1;
}

.Hheading2 {
  display: flex;
  font-family: "Poppins-Light";
  color: white;

  cursor: pointer;
  flex: 1 1;
  float: left;
  justify-content: flex-end;
}

.logoimg,
.logoimg2 {
  display: flex;
  font-family: "Poppins-SemiBold";
  color: white;

  cursor: pointer;
  flex: 1 1;
  float: left;
  justify-content: flex-start;
}

.Hheading span:hover,
.Hheading1 span:hover,
.Hheading2 span:hover {
  color: rgb(183, 183, 183);
}

.Hheading,
.Hheading1,
.Hheading2 {
  width: auto;
  height: auto;
  cursor: pointer;
}

.Home-Header {
  display: flex;
  width: 100%;
  height: 10vh;
  margin: 0 auto;
  align-items: center;
  background-color: rgb(74, 74, 74);
  position: fixed;
  padding-top: 0px;
  justify-content: space-between;
  z-index: 3;
  top: 0px;
  margin-top: 0px;
}

.HH {
  display: flex;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  background-color: rgb(74, 74, 74);
  z-index: 3;
}

.logoimg2 {
  display: none;
}

@media screen and (max-width: 1483px) {
  .Hheading1 {
    flex: 0.5 1;
  }
}

@media screen and (max-width: 1034px) {
  .logoimg2 {
    display: flex;
    font-family: "Poppins-SemiBold";
    color: white;

    cursor: pointer;
    flex: 1 1;
    float: left;
    justify-content: flex-start;

    -webkit-transform: scale(0.7);

    transform: scale(0.7);
  }

  .logoimg {
    display: none;
  }
}
@media screen and (max-width: 694px) {
  .Hheading2,
  .Hheading1 {
    display: none;
  }

  .Hheading {
    display: flex;
    font-family: "Poppins-Light";
    color: white;

    cursor: pointer;
    flex: 1 1;
    float: left;
    justify-content: flex-start;
  }
}

.Contact {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mainC {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 20%;
}

.titleC {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 50px;
  margin: auto;
}

.formC {
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  width: 100%;
}

.ButtonC {
  background: none;
  border: none;
  color: #ea1d6f;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  justify-content: center;
  float: right;
  margin: 0;
}

.ButtonC:hover {
  color: #b9134f;
}

.ButtonsC {
  justify-content: center;
}

.inputC {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  color: rgb(50, 50, 50);
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.formCddsadsa {
  padding: 10px 10px !important;
}

@media screen and (max-width: 658px) {
  .formCL {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@media screen and (max-width: 530px) {
  .formCCL {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@media screen and (max-width: 548px) {
  .formCL {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  .titleCol {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    margin: 0;
  }
}

@media screen and (max-width: 424px) {
  .formCL {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  .titleCol {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    margin: 0;
  }
}

.burgermenu {
  display: none;
  height: 30px;
  width: 35px;
  z-index: 90909999;
  flex-direction: column;
}
.bar {
  height: 5px;
  z-index: 909099999;
  background-color: white;
  display: flex;
  margin: 3px;
  cursor: pointer;
  flex-direction: column;
}

.burger1 {
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(71, 71, 71);
  transition: all 0.7s ease;
  background-size: cover;
}

@media screen and (max-width: 450px) {
  .burger1 {
    height: 300%;
  }
  .Hheading1b,
  .Hheading2b {
    font-size: 45px;
  }
}

/* 
@media screen and (max-height: 1077px) {
  .home-footer {
    height: 1000vh;
  }
} */

@media screen and (max-width: 209px) {
  .burger1 {
    height: 325%;
  }
}

.Hheading1b,
.Hheading2b {
  color: white;
  font-size: 40px;
}

@media screen and (max-width: 694px) {
  .Hheading2,
  .Hheading1 {
    display: none;
  }

  .burgermenu {
    display: flex;
    flex: 1 1;
    flex-direction: column;
  }
  .logoimg2 {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 0;
    position: absolute;
    left: 100px;
  }

  .Hheading {
    display: flex;
    font-family: "Poppins-Light";
    color: white;

    cursor: pointer;
    flex: 1 1;
    float: left;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1373px) {
  .mainC {
    width: 70%;
  }
}

@media screen and (max-height: 763px) {
  .mainC {
    margin-top: 300px;
  }
}

.Contact {
  overflow: scroll !important;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.CL {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="url"],
.Autocomplete,
select,
textarea,
input[type="password"] {
  width: 20%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="submit"],
input[type="button"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.noDonate:hover {
  background-color: #c63939 !important;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover,
input[type="button"]:hover {
  background-color: #45a049;
}
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.logoimgCL {
  position: fixed;
  left: 0px;
  top: 15px;
}

.formCL {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-height: 739px) {
  .titleCol {
    display: none;
  }
}

@media screen and (max-height: 513px) {
  .formCL {
    margin-top: 80px;
  }
}

@media screen and (max-height: 464px) {
  .formCL {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  .logoimg2 {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.login {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: -2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.titleLog {
  margin-bottom: 50px;
}
.formL {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  margin-top: 10vh;
}

/* fancy Button */
.fancy-button22121,
.fancy-button {
  display: inline-block;
  margin: 30px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

.fancy-button22121,
.ee {
  -webkit-transform: scale(0.61);
  transform: scale(0.61);
}
.oo {
  width: unset !important;
}
.fancy-button {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.fancy-button22121:before,
.fancy-button:before {
  content: "";
  display: inline-block;
  height: 40px;
  position: absolute;
  bottom: -5px;
  left: 30px;
  right: 30px;
  z-index: -1;
  border-radius: 30em;
  -webkit-filter: blur(20px) brightness(0.95);
  filter: blur(20px) brightness(0.95);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
}
.fancy-button22121 i,
.fancy-button i {
  margin-top: -1px;
  margin-right: 20px;
  font-size: 1.265em;
  vertical-align: middle;
}
.fancy-button22121 span,
.fancy-button span {
  display: flex;
  padding: 18px 60px;
  border-radius: 50em;
  position: relative;
  z-index: 2;
  will-change: transform, filter;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
  align-items: center;
  font-size: x-large;
}
.fancy-button22121:focus,
.fancy-button22121:active,
.fancy-button:focus,
.fancy-button:active {
  color: #fff;
}
.fancy-button22121:hover,
.fancy-button:hover {
  color: #fff;
}
.fancy-button22121:hover span,
.fancy-button:hover span {
  -webkit-filter: brightness(1.05) contrast(1.05);
  filter: brightness(1.05) contrast(1.05);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.fancy-button22121:hover:before,
.fancy-button:hover:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
}
.fancy-button22121.pop-onhover:before,
.fancy-button.poponhover:before {
  opacity: 0;
  bottom: 10px;
}
.fancy-button22121.pop-onhover:hover:before,
.fancy-button.poponhover:hover:before {
  bottom: -7px;
  opacity: 1;
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
.fancy-button22121.pop-onhover:hover span,
.fancy-button.poponhover:hover span {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.fancy-button22121.pop-onhover:hover:active span,
.fancy-button22121.pop-onhover:hover:active span {
  -webkit-filter: brightness(1) contrast(1);
  filter: brightness(1) contrast(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.15s ease-out;
}
.fancy-button22121.pop-onhover:hover:active:before,
.fancy-button22121.pop-onhover:hover:active:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
  transition: all 0.2s ease-out;
}
.buttonb {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin: 20px;
}
.buttonb svg {
  height: 55px;
  width: 150px;
  fill: none;
  stroke-width: 5;
}
.buttonb svg .o1 {
  stroke: rgba(255, 179, 65, 0.7);
  fill: #ffa114;
  transition: all 1s ease-in-out;
  width: 150%;
}
.buttonb svg .o2 {
  stroke: white;
  stroke-dasharray: 20 420;
  stroke-dashoffset: 20;
  transition: all 1s ease-in-out;
}
.buttonb svg text {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  margin: auto 0;
  text-transform: uppercase;
  letter-spacing: 3px;
  z-index: 99090909;
}
text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonb:hover .o1 {
  fill: rgba(204, 129, 16, 0.3);
}
.buttonb:hover .o2 {
  stroke-dashoffset: -420;
}
.loginB {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.spanbois2 {
  margin-right: 30px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.spanbois {
  margin-left: 30px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@media screen and (max-width: 658px) {
  .formL {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@media screen and (max-width: 504px) {
  .formL {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@media screen and (max-width: 374px) {
  .formL {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  .titleLog {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    margin: 0;
  }
}

@media screen and (max-width: 298px) {
  .formL {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  .titleLog {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    margin: 0;
  }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.BLogin {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.spacer {
  width: 100%;
  height: 35vh;
}

.titleB {
  font-size: 50px;

  margin-top: 50vh !important;
}

@media screen and (max-width: 628px) {
  .titleB {
    font-size: 30px;
  }
}
@media screen and (max-width: 520px) {
  .titleB {
    font-size: 20px;
  }
}
@media screen and (max-height: 1048px) {
  .titleB {
    margin-top: 70vh !important;
  }
}
@media screen and (max-height: 899px) {
  .titleB {
    margin-top: 100vh !important;
  }
}

.date-field {
  width: 30px;
  text-align: center;
}
.date-field--year {
  width: 60px;
}
.single-date-field {
  width: 120px;
}
.dobwrap {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.field-inline-block {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  width: 100;
}
.field-inline-block label {
  text-align: center;
}

.mainBR {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.formBR a {
  display: flex;
  justify-content: center;
}

.oo {
  width: 50%;
  margin: auto;
}
.fancy-button {
  margin: auto;
}

.formBR label {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

#formBR1,
#titleB1 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#titleB1 {
  position: absolute;
  left: 50%;
  top: -5%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.termsforstripe {
  display: flex;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

@media screen and (max-width: 654px) {
  .termsforstripe {
    font-size: xx-small;
  }
}

@media screen and (max-width: 554px) {
  .termsforstripe {
    font-size: 7px;
  }
}

@media screen and (max-width: 428px) {
  .termsforstripe {
    font-size: 5px;
  }
}

@media screen and (max-width: 582px) {
  .saysome {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 542px) {
  .formBR {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@media screen and (max-width: 444px) {
  .formBR {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}
@media screen and (max-width: 546px) {
  .titleB {
    display: none;
  }
  .formBR {
    margin-top: 100vh !important;
  }
}
@media screen and (max-width: 354px) {
  .formBR {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    margin-top: 49vh !important;
  }
  .titleB {
    display: none;
  }
}
@media screen and (max-height: 758px) {
  .titleB {
    margin-top: 150vh !important;
  }
}
@media screen and (max-height: 587px) {
  .titleB {
    margin-top: 180vh !important;
  }
}
@media screen and (max-height: 506px) {
  .titleB {
    margin-top: 200vh !important;
  }
}

/* @media screen and (max-height: 754px) {
  .formBR {
    margin-top: 400px;
  }
} */
/* @media screen and (max-height: 670px) {
  .formBR {
    margin-top: 500px;
  }
}
@media screen and (max-height: 557px) {
  .formBR {
    margin-top: 600px;
  }
}
@media screen and (max-height: 433px) {
  .formBR {
    margin-top: 700px;
  }
} */

@media screen and (max-height: 412px) {
  .logoimg2 {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

.link {
  /* RESET */
  text-decoration: none;
  padding: 0px 2px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #333;
  vertical-align: bottom;
  transition: color 0.3s ease-out;
}

body {
  -webkit-font-smoothing: antialiased;
}

.link::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(calc(100% - 2px));
          transform: translateY(calc(100% - 2px));
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #64b3f4 0%, #c2e59c 100%);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.link:hover {
  color: rgb(69, 69, 69);
}
.link:hover::before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.ze {
  width: 60% !important;
}

.help {
  background-color: rgba(74, 74, 74, 0.909);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9999999999999999999999999999999;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.help label,
.help li {
  color: white;
}

.help a {
  color: white !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.f {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: auto !important;
}

@media screen and (max-width: 763px) {
  .Bdash {
    height: 50px !important;
    font-size: 15px !important;
  }
}

@media screen and (max-width: 403px) {
  .Bdash {
    height: 45px !important;
    font-size: 10px !important;
  }
}

@media screen and (max-width: 699px) {
  .hjdfs {
    top: 0 !important;
    left: 50% !important;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }
  .hjdfsz {
    top: 10% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }
}

@charset "UTF-8";
/*


UN COMMENT TO EDIT!!


*/
.section1 {
  width: 50%;
  height: 100vh;
  position: absolute;
  box-shadow: -10px -10px 30px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Description {
  width: 89%;
  word-wrap: break-word;
}

.section2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 100vh;
  right: 0;
  position: absolute;
  box-shadow: -10px -10px 30px 4px rgba(0, 0, 0, 0.1);
}

.section2 h6 {
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.inputprice {
  position: absolute;
  top: 43%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.checkout {
  width: 121px;
  height: 32px;
}

.goback {
  position: absolute;
  margin: 30px;
  cursor: pointer;
  z-index: 909090;
  transition: margin-left 0.3s;
}

.goback:hover {
  color: #005b90;
  margin-left: 27px;
}

@media screen and (max-width: 1207px) {
  .Title {
    font-size: 15px;
  }
  .Description {
    font-size: 10px;
  }
}

@media screen and (max-width: 571px) {
  .Title {
    font-size: 12px;
  }
  .Description {
    font-size: 8px;
  }
  .PhoneNumber {
    font-size: 7px;
  }
}

#back {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.logoimgfordabuy {
  width: 300px;
}

.modal {
  width: 45%;
  background-color: #494949;
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  color: aliceblue;
  position: absolute;
  height: 45%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: -100px -100px 300px 40px rgba(0, 0, 0, 0.1);
  opacity: 0.96;
  transition: opacity 0.3s ease-in-out;
}

.modal h3 {
  font-size: 30px;
}

/* Variables */
:root {
  --gray-offset: rgba(0, 0, 0, 0.03);
  --gray-border: rgba(0, 0, 0, 0.15);
  --gray-light: rgba(0, 0, 0, 0.4);
  --gray-mid: rgba(0, 0, 0, 0.7);
  --gray-dark: rgba(0, 0, 0, 0.9);
  --body-color: var(--gray-mid);
  --headline-color: var(--gray-dark);
  --accent-color: #61dafb;
  --radius: 6px;
  --logo-image: url("https://www.logo.wine/a/logo/Stripe_(company)/Stripe_(company)-Powered-by-Stripe-Logo.wine.svg");
  --form-width: 600px;
}

/* Base */
#h1,
#h2,
#h3,
#h4,
#h5,
#h6 {
  color: rgba(0, 0, 0, 0.7);
  color: var(--body-color);
  margin-top: 2px;
  margin-bottom: 4px;
}

#h1 {
  font-size: 27px;
  color: #61dafb;
  color: var(--headline-color);
}

#h4 {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
  word-wrap: break-word;
}

/* Layout */
.sr-root {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  justify-content: center;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
}

.sr-header {
  margin-bottom: 32px;
}

.sr-payment-summary {
  margin-bottom: 20px;
}

.sr-main,
.sr-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-self: center;
}

.sr-main {
  width: 600px;
  width: var(--form-width);
  margin-top: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.sr-content {
  padding-left: 48px;
}

@media screen and (max-width: 1080px) {
  #map {
    width: 100vw;
    height: 35%;
  }
}

@media screen and (max-width: 436px) {
  .container1 {
    width: 80%;
  }
}

@media screen and (max-width: 360px) {
  .container1 {
    width: 70%;
  }
}

@media screen and (max-width: 314px) {
  #map {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.sr-header__logo {
  background-image: url("https://storage.googleapis.com/stripe-sample-images/logo-pasha.svg");
  background-image: var(--logo-image);
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}

.sr-legal-text {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

.sr-field-error {
  color: #61dafb;
  color: var(--accent-color);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

/* Form */
.sr-form-row {
  margin: 16px 0;
}

/* Inputs */
.sr-input,
.sr-select,
input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="button"],
input[type="submit"],
input,
input[type="number"] {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border: 1px solid var(--gray-border);
  border-radius: 6px;
  border-radius: var(--radius);
  padding: 5px 12px;
  height: 44px;
  width: 100%;
  transition: box-shadow 0.2s ease;
  background: white;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #32325d;
  transition: all ease 0.2s;
}

textarea {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border: 1px solid var(--gray-border);
  border-radius: 6px;
  border-radius: var(--radius);
  height: 44px;
  width: 100%;
  transition: box-shadow 0.2s ease;
  background: white;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #32325d;
  transition: all ease 0.2s;
}

.sr-input:focus,
input[type="text"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
input:focus,
textarea:focus,
option:focus,
.focused {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
  z-index: 9;
}

.sr-input::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
}

.sr-input:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
}

.sr-input::-ms-input-placeholder,
input[type="text"]::-ms-input-placeholder,
input[type="number"]::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
}

.sr-input::-webkit-input-placeholder, input[type="text"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
}

.sr-input:-ms-input-placeholder, input[type="text"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
}

.sr-input::-ms-input-placeholder, input[type="text"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
}

.sr-input::placeholder,
input[type="text"]::placeholder,
input[type="number"]::placeholder {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
}

/* Checkbox */
.sr-checkbox-label {
  position: relative;
  cursor: pointer;
}

.sr-checkbox-label input {
  opacity: 0;
  margin-right: 6px;
}

.sr-checkbox-label .sr-checkbox-check {
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border: 1px solid var(--gray-border);
  border-radius: 4px;
  transition: all 0.2s ease;
}

.sr-checkbox-label input:focus ~ .sr-checkbox-check {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
}

.sr-checkbox-label input:checked ~ .sr-checkbox-check {
  background-color: #61dafb;
  background-color: var(--accent-color);
  background-image: url("https://storage.googleapis.com/stripe-sample-images/icon-checkmark.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: -1px -1px;
}

/* Select */
.sr-select {
  display: block;
  height: 44px;
  margin: 0;
  background-image: url("https://storage.googleapis.com/stripe-sample-images/icon-chevron-down.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 12px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.sr-select::-ms-expand {
  display: none;
}

.sr-select:hover {
  cursor: pointer;
}

input:focus,
option:focus,
textarea:focus {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
}

.sr-select option {
  font-weight: 400;
}

.sr-select:invalid {
  color: rgba(0, 0, 0, 0.4);
  color: var(--gray-light);
}

/* Combo inputs */
.sr-combo-inputs {
  display: flex;
  flex-direction: column;
}

.sr-combo-inputs input,
.sr-combo-inputs .sr-select {
  border-radius: 0;
  border-bottom: 0;
}

.sr-combo-inputs > input:first-child,
.sr-combo-inputs > .sr-select:first-child {
  border-radius: 6px 6px 0 0;
  border-radius: var(--radius) var(--radius) 0 0;
}

.sr-combo-inputs > input:last-child,
.sr-combo-inputs > .sr-select:last-child {
  border-radius: 0 0 6px 6px;
  border-radius: 0 0 var(--radius) var(--radius);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid var(--gray-border);
}

.sr-combo-inputs > .sr-combo-inputs-row:last-child input:first-child {
  border-radius: 0 0 0 6px;
  border-radius: 0 0 0 var(--radius);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid var(--gray-border);
}

.sr-combo-inputs > .sr-combo-inputs-row:last-child input:last-child {
  border-radius: 0 0 6px 0;
  border-radius: 0 0 var(--radius) 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid var(--gray-border);
}

.sr-combo-inputs > .sr-combo-inputs-row:first-child input:first-child {
  border-radius: 6px 0 0 0;
  border-radius: var(--radius) 0 0 0;
}

.sr-combo-inputs > .sr-combo-inputs-row:first-child input:last-child {
  border-radius: 0 6px 0 0;
  border-radius: 0 var(--radius) 0 0;
}

.sr-combo-inputs > .sr-combo-inputs-row:first-child input:only-child {
  border-radius: 6px 6px 0 0;
  border-radius: var(--radius) var(--radius) 0 0;
}

.sr-combo-inputs-row {
  width: 100%;
  display: flex;
}

.sr-combo-inputs-row > input {
  width: 100%;
  border-radius: 0;
}

.sr-combo-inputs-row > input:first-child:not(:only-child) {
  border-right: 0;
}

.sr-combo-inputs-row:not(:first-of-type) .sr-input {
  border-radius: 0 0 6px 6px;
  border-radius: 0 0 var(--radius) var(--radius);
}

/* Buttons and links */
#bbutton {
  background: #61dafb;
  background: var(--accent-color);
  border-radius: 6px;
  border-radius: var(--radius);
  color: #282c34;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}

#bbutton:hover {
  -webkit-filter: contrast(115%);
  filter: contrast(115%);
}

#bbutton:active {
  -webkit-transform: translateY(0px) scale(0.98);
  transform: translateY(0px) scale(0.98);
  -webkit-filter: brightness(0.9);
  filter: brightness(0.9);
}

#bbutton:disabled {
  opacity: 0.5;
  cursor: none;
}

.sr-payment-form button,
.fullwidth {
  width: 100%;
}

/* Code block */
.sr-callout {
  background: rgba(0, 0, 0, 0.03);
  background: var(--gray-offset);
  padding: 12px;
  border-radius: 6px;
  border-radius: var(--radius);
  max-height: 200px;
  overflow: auto;
}

code,
pre {
  font-family: "SF Mono", "IBM Plex Mono", "Menlo", monospace;
  font-size: 12px;
}

/* Stripe Element placeholder */
.sr-card-element {
  padding-top: 12px;
}

/* Responsiveness */
@media (max-width: 720px) {
  .sr-root {
    flex-direction: column;
    justify-content: flex-start;
    padding: 48px 20px;
    min-width: 320px;
  }
  .sr-header__logo {
    background-position: center;
  }
  .sr-payment-summary {
    text-align: center;
  }
  .sr-content {
    display: none;
  }
  .sr-main {
    width: 100%;
  }
}

/* Pasha styles – Brand-overrides, can split these out */
:root {
  --headline-color: var(--accent-color);
  --logo-image: url("https://storage.googleapis.com/stripe-sample-images/logo-pasha.svg");
}

.pasha-image-stack {
  display: -ms-grid;
  display: grid;
  grid-gap: 12px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
}

.pasha-image-stack img,
.pasha-image img {
  border-radius: 6px;
  border-radius: var(--radius);
  background-color: rgba(0, 0, 0, 0.15);
  background-color: var(--gray-border);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  transition: all 0.8s ease;
  opacity: 0;
  width: auto;
  max-width: 150px;
  height: auto;
  max-height: 100px;
}

.pasha-image {
  text-align: center;
  margin-top: 20px;
}

.pasha-image img {
  opacity: 1;
}

.pasha-image-stack img:nth-child(1) {
  -webkit-transform: translate(30px, 15px);
  transform: translate(30px, 15px);
  opacity: 1;
}

.pasha-image-stack img:nth-child(2) {
  -webkit-transform: translate(-28px, 0px);
  transform: translate(-28px, 0px);
  opacity: 1;
}

.pasha-image-stack img:nth-child(3) {
  -webkit-transform: translate(64px, -50px);
  transform: translate(64px, -50px);
  opacity: 1;
}

/* todo: spinner/processing state, errors, animations */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #61dafb;
  background: var(--accent-color);
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #61dafb;
  background: var(--accent-color);
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Custom */
.container1 {
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
}

.container1 .pasha-image-stack {
  margin-top: 20px;
}

.container1:first-of-type {
  margin-right: 20px;
}

.quantity-setter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.quantity-setter input {
  border-radius: 0;
  width: 50px;
  padding: 0;
  text-align: center;
}

.quantity-setter .increment-btn {
  margin-top: 0;
  border-radius: 0;
  border: 1px solid #61dafb;
  border: 1px solid var(--accent-color);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Animated form */
.sr-root {
  -webkit-animation: 0.4s form-in;
  animation: 0.4s form-in;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}

#Buy {
  width: 100vw !important;
}

.sr-payment-form .sr-form-row {
  -webkit-animation: 0.4s field-in;
  animation: 0.4s field-in;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

/* need saas for loop :D  */
.sr-payment-form .sr-form-row:nth-child(1) {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.sr-payment-form .sr-form-row:nth-child(2) {
  -webkit-animation-delay: 60ms;
  animation-delay: 60ms;
}

.sr-payment-form .sr-form-row:nth-child(3) {
  -webkit-animation-delay: 120ms;
  animation-delay: 120ms;
}

.sr-payment-form .sr-form-row:nth-child(4) {
  -webkit-animation-delay: 180ms;
  animation-delay: 180ms;
}

.sr-payment-form .sr-form-row:nth-child(5) {
  -webkit-animation-delay: 240ms;
  animation-delay: 240ms;
}

.sr-payment-form .sr-form-row:nth-child(6) {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}

.hidden {
  display: none;
}

@-webkit-keyframes field-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(8px) scale(0.95);
    transform: translateY(8px) scale(0.95);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes field-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(8px) scale(0.95);
    transform: translateY(8px) scale(0.95);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@-webkit-keyframes form-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes form-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* CODESANDBOX ONLY BANNER */
.banner {
  max-width: 825px;
  margin: 0 auto;
  padding: 0 22px;
  font-size: 14px;
  background: white;
  color: #6a7c94;
  border-radius: 22px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  line-height: 1.15;
  position: fixed;
  bottom: 2vh;
  left: 0;
  right: 0;
  text-align: center;
}

.banner span {
  display: inline-block;
  width: 100%;
}

.banner a {
  color: #61dafb;
  color: var(--accent-color);
}

#map {
  position: absolute;
  height: 40%;
  width: 100%;
  margin-bottom: 100px !important;
}
/*# sourceMappingURL=Buy.css.map */

.rocket-button {
  --background: #262730;
  --text: #fff;
  --check: #5c86ff;
  --blue: #5c86ff;
  --blue-transparent: rgba(92, 134, 255, 0);
  --dot: #51fe5d;
  --dot-shadow: rgba(81, 254, 93, 0.3);
  --dot1: #fe5151;
  --dot-shadow1: rgba(254, 81, 81, 0.3);
  --smoke: rgba(247, 248, 255, 0.9);
  --rocket: #eef0fd;
  --rocket-shadow-left: #fff;
  --rocket-shadow-right: #d3d4ec;
  --rocket-wing-right: #c2c3d9;
  --rocket-wing-left: #d3d4ec;
  --rocket-window: #275efe;
  --rocket-window-shadow: #c2c3d9;
  --rocket-line: #9ea0be;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  padding: 14px 28px;
  display: table;
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  text-decoration: none;
  color: var(--text);
  -webkit-filter: drop-shadow(0px 0px 15px rgba(81, 254, 93, 0.3));
          filter: drop-shadow(0px 0px 15px rgba(81, 254, 93, 0.3));
}

.rocket-button:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: scale(1) translateZ(0);
  -webkit-transform: scale(var(--s, 1)) translateZ(0);
          transform: scale(1) translateZ(0);
          transform: scale(var(--s, 1)) translateZ(0);
  position: absolute;
  background: var(--background);
}

.rocket-button .default,
.rocket-button .success,
.rocket-button .animation {
  z-index: 1;
}

.rocket-button .default span,
.rocket-button .success span {
  display: block;
  transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: translateX(0);
  -webkit-transform: translateX(var(--x, 0));
          transform: translateX(0);
          transform: translateX(var(--x, 0));
  opacity: 1;
  opacity: var(--o, 1);
  -webkit-filter: blur(0px);
  -webkit-filter: blur(var(--b, 0px));
          filter: blur(0px);
          filter: blur(var(--b, 0px));
}

.rocket-button .default {
  position: relative;
  display: flex;
}

.rocket-button .default:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 7px 8px 0 0;
  box-shadow: 0 0 0 1px var(--dot-shadow);
  -webkit-animation: pulse 1s ease infinite;
          animation: pulse 1s ease infinite;
  vertical-align: top;
  display: inline-block;
  transition: opacity 0.3s linear;
  opacity: 1;
  opacity: var(--o, 1);
  background: var(--dot);
}

.rocket-button .default1 {
  position: relative;
  display: flex;
}

.rocket-button .default1:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 7px 8px 0 0;
  box-shadow: 0 0 0 1px var(--dot-shadow1);
  -webkit-animation: pulse1 1s ease infinite;
          animation: pulse1 1s ease infinite;
  vertical-align: top;
  display: inline-block;
  transition: opacity 0.3s linear;
  opacity: 1;
  opacity: var(--o, 1);
  background: var(--dot1);
}

.rocket-button .success {
  opacity: 0;
  opacity: var(--o, 0);
  position: absolute;
  display: flex;
  top: 14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.rocket-button .success svg {
  width: 13px;
  height: 11px;
  stroke-width: 2;
  stroke-dasharray: 20px;
  stroke-dashoffset: 20px;
  stroke-dashoffset: var(--o, 20px);
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  display: block;
  color: var(--check);
  margin: 4px 8px 0 0;
}

.rocket-button .success > div {
  display: flex;
}

.rocket-button .success > div span {
  --o: 0;
  --x: 8px;
  --b: 2px;
}

.rocket-button .animation {
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
  pointer-events: none;
  overflow: hidden;
  position: absolute;
}

.rocket-button .animation .smoke {
  left: 50%;
  top: 100%;
  position: absolute;
}

.rocket-button .animation .smoke i {
  opacity: 0;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  bottom: var(--b, -20px);
  left: -12px;
  left: var(--l, -12px);
  width: 32px;
  width: var(--s, 32px);
  height: 32px;
  height: var(--s, 32px);
  background: var(--smoke);
}

.rocket-button .animation .smoke i:nth-child(2) {
  --s: 20px;
  --l: -24px;
  --b: -10px;
  --d: 50ms;
}

.rocket-button .animation .smoke i:nth-child(3) {
  --s: 22px;
  --l: 0;
  --b: -12px;
  --d: 20ms;
}

.rocket-button .animation .smoke i:nth-child(4) {
  --s: 12px;
  --l: 16px;
  --b: -6px;
  --d: 120ms;
}

.rocket-button .animation .smoke i:nth-child(5) {
  --s: 24px;
  --l: -20px;
  --b: -14px;
  --d: 80ms;
}

.rocket-button .animation .smoke i:nth-child(6) {
  --s: 12px;
  --l: -28px;
  --b: -8px;
  --d: 60ms;
}

.rocket-button .animation .rocket {
  position: absolute;
  left: 50%;
  top: 100%;
  z-index: 1;
  margin: 0 0 0 -12px;
}

.rocket-button .animation .rocket:before {
  content: "";
  margin-left: -3px;
  left: 50%;
  top: 32px;
  position: absolute;
  width: 6px;
  border-radius: 2px;
  height: 32px;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  background: linear-gradient(var(--blue), var(--blue-transparent));
}

.rocket-button .animation .rocket svg {
  width: 24px;
  height: 36px;
  display: block;
}

.rocket-button:active {
  --s: 0.95;
}

.rocket-button.live:before {
  -webkit-animation: shake 1.5s ease 0.6s;
          animation: shake 1.5s ease 0.6s;
}

.rocket-button.live .default:before {
  --o: 0;
}

.rocket-button.live .default span {
  --o: 0;
  --x: 8px;
  --b: 2px;
  transition: opacity 0.3s ease var(--d), -webkit-transform 0.3s ease var(--d), -webkit-filter 0.3s ease var(--d);
  transition: transform 0.3s ease var(--d), opacity 0.3s ease var(--d), filter 0.3s ease var(--d);
  transition: transform 0.3s ease var(--d), opacity 0.3s ease var(--d), filter 0.3s ease var(--d), -webkit-transform 0.3s ease var(--d), -webkit-filter 0.3s ease var(--d);
}

.rocket-button.live .success {
  --o: 1;
}

.rocket-button.live .success span {
  --o: 1;
  --x: 0;
  --b: 0;
  transition: opacity 0.3s ease calc(var(--d) + 2200ms), -webkit-transform 0.3s ease calc(var(--d) + 2200ms), -webkit-filter 0.3s ease calc(var(--d) + 2200ms);
  transition: transform 0.3s ease calc(var(--d) + 2200ms), opacity 0.3s ease calc(var(--d) + 2200ms), filter 0.3s ease calc(var(--d) + 2200ms);
  transition: transform 0.3s ease calc(var(--d) + 2200ms), opacity 0.3s ease calc(var(--d) + 2200ms), filter 0.3s ease calc(var(--d) + 2200ms), -webkit-transform 0.3s ease calc(var(--d) + 2200ms), -webkit-filter 0.3s ease calc(var(--d) + 2200ms);
}

.rocket-button.live .success svg {
  --o: 0;
  transition: stroke-dashoffset 0.3s ease 2.25s;
}

.rocket-button.live .animation .rocket {
  -webkit-animation: rocket 2s ease forwards 0.4s;
          animation: rocket 2s ease forwards 0.4s;
}

.rocket-button.live .animation .rocket:before {
  -webkit-animation: rocket-light 2s ease forwards 0.4s;
          animation: rocket-light 2s ease forwards 0.4s;
}

.rocket-button.live .animation .smoke i {
  -webkit-animation: smoke 1.7s ease forwards calc(var(--d) + 600ms);
  -webkit-animation: var(--n, smoke) 1.7s ease forwards calc(var(--d) + 600ms);
          animation: smoke 1.7s ease forwards calc(var(--d) + 600ms);
          animation: var(--n, smoke) 1.7s ease forwards calc(var(--d) + 600ms);
}

.rocket-button.live .animation .smoke i:nth-child(3), .rocket-button.live .animation .smoke i:nth-child(6) {
  --n: smoke-alt;
}

.rocket-button1 {
  --background: #262730;
  --text: #fff;
  --check: #5c86ff;
  --blue: #5c86ff;
  --blue-transparent: rgba(92, 134, 255, 0);
  --dot: #51fe5d;
  --dot-shadow: rgba(81, 254, 93, 0.3);
  --dot1: #fe5151;
  --dot-shadow1: rgba(254, 81, 81, 0.3);
  --smoke: rgba(247, 248, 255, 0.9);
  --rocket: #eef0fd;
  --rocket-shadow-left: #fff;
  --rocket-shadow-right: #d3d4ec;
  --rocket-wing-right: #c2c3d9;
  --rocket-wing-left: #d3d4ec;
  --rocket-window: #275efe;
  --rocket-window-shadow: #c2c3d9;
  --rocket-line: #9ea0be;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  padding: 14px 28px;
  display: table;
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  text-decoration: none;
  color: var(--text);
  -webkit-filter: drop-shadow(0px 0px 15px rgba(254, 81, 81, 0.3));
          filter: drop-shadow(0px 0px 15px rgba(254, 81, 81, 0.3));
}

.rocket-button1:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: scale(1) translateZ(0);
  -webkit-transform: scale(var(--s, 1)) translateZ(0);
          transform: scale(1) translateZ(0);
          transform: scale(var(--s, 1)) translateZ(0);
  position: absolute;
  background: var(--background);
}

.rocket-button1 .default,
.rocket-button1 .success,
.rocket-button1 .animation {
  z-index: 1;
}

.rocket-button1 .default span,
.rocket-button1 .success span {
  display: block;
  transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: translateX(0);
  -webkit-transform: translateX(var(--x, 0));
          transform: translateX(0);
          transform: translateX(var(--x, 0));
  opacity: 1;
  opacity: var(--o, 1);
  -webkit-filter: blur(0px);
  -webkit-filter: blur(var(--b, 0px));
          filter: blur(0px);
          filter: blur(var(--b, 0px));
}

.rocket-button1 .default {
  position: relative;
  display: flex;
}

.rocket-button1 .default:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 7px 8px 0 0;
  box-shadow: 0 0 0 1px var(--dot-shadow);
  -webkit-animation: pulse 1s ease infinite;
          animation: pulse 1s ease infinite;
  vertical-align: top;
  display: inline-block;
  transition: opacity 0.3s linear;
  opacity: 1;
  opacity: var(--o, 1);
  background: var(--dot);
}

.rocket-button1 .default1 {
  position: relative;
  display: flex;
}

.rocket-button1 .default1:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 7px 8px 0 0;
  box-shadow: 0 0 0 1px var(--dot-shadow1);
  -webkit-animation: pulse1 1s ease infinite;
          animation: pulse1 1s ease infinite;
  vertical-align: top;
  display: inline-block;
  transition: opacity 0.3s linear;
  opacity: 1;
  opacity: var(--o, 1);
  background: var(--dot1);
}

.rocket-button1 .success {
  opacity: 0;
  opacity: var(--o, 0);
  position: absolute;
  display: flex;
  top: 14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.rocket-button1 .success svg {
  width: 13px;
  height: 11px;
  stroke-width: 2;
  stroke-dasharray: 20px;
  stroke-dashoffset: 20px;
  stroke-dashoffset: var(--o, 20px);
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  display: block;
  color: var(--check);
  margin: 4px 8px 0 0;
}

.rocket-button1 .success > div {
  display: flex;
}

.rocket-button1 .success > div span {
  --o: 0;
  --x: 8px;
  --b: 2px;
}

.rocket-button1 .animation {
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
  pointer-events: none;
  overflow: hidden;
  position: absolute;
}

.rocket-button1 .animation .smoke {
  left: 50%;
  top: 100%;
  position: absolute;
}

.rocket-button1 .animation .smoke i {
  opacity: 0;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  bottom: var(--b, -20px);
  left: -12px;
  left: var(--l, -12px);
  width: 32px;
  width: var(--s, 32px);
  height: 32px;
  height: var(--s, 32px);
  background: var(--smoke);
}

.rocket-button1 .animation .smoke i:nth-child(2) {
  --s: 20px;
  --l: -24px;
  --b: -10px;
  --d: 50ms;
}

.rocket-button1 .animation .smoke i:nth-child(3) {
  --s: 22px;
  --l: 0;
  --b: -12px;
  --d: 20ms;
}

.rocket-button1 .animation .smoke i:nth-child(4) {
  --s: 12px;
  --l: 16px;
  --b: -6px;
  --d: 120ms;
}

.rocket-button1 .animation .smoke i:nth-child(5) {
  --s: 24px;
  --l: -20px;
  --b: -14px;
  --d: 80ms;
}

.rocket-button1 .animation .smoke i:nth-child(6) {
  --s: 12px;
  --l: -28px;
  --b: -8px;
  --d: 60ms;
}

.rocket-button1 .animation .rocket {
  position: absolute;
  left: 50%;
  top: 100%;
  z-index: 1;
  margin: 0 0 0 -12px;
}

.rocket-button1 .animation .rocket:before {
  content: "";
  margin-left: -3px;
  left: 50%;
  top: 32px;
  position: absolute;
  width: 6px;
  border-radius: 2px;
  height: 32px;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  background: linear-gradient(var(--blue), var(--blue-transparent));
}

.rocket-button1 .animation .rocket svg {
  width: 24px;
  height: 36px;
  display: block;
}

.rocket-button1:active {
  --s: 0.95;
}

.rocket-button1.live:before {
  -webkit-animation: shake 1.5s ease 0.6s;
          animation: shake 1.5s ease 0.6s;
}

.rocket-button1.live .default:before {
  --o: 0;
}

.rocket-button1.live .default span {
  --o: 0;
  --x: 8px;
  --b: 2px;
  transition: opacity 0.3s ease var(--d), -webkit-transform 0.3s ease var(--d), -webkit-filter 0.3s ease var(--d);
  transition: transform 0.3s ease var(--d), opacity 0.3s ease var(--d), filter 0.3s ease var(--d);
  transition: transform 0.3s ease var(--d), opacity 0.3s ease var(--d), filter 0.3s ease var(--d), -webkit-transform 0.3s ease var(--d), -webkit-filter 0.3s ease var(--d);
}

.rocket-button1.live .success {
  --o: 1;
}

.rocket-button1.live .success span {
  --o: 1;
  --x: 0;
  --b: 0;
  transition: opacity 0.3s ease calc(var(--d) + 2200ms), -webkit-transform 0.3s ease calc(var(--d) + 2200ms), -webkit-filter 0.3s ease calc(var(--d) + 2200ms);
  transition: transform 0.3s ease calc(var(--d) + 2200ms), opacity 0.3s ease calc(var(--d) + 2200ms), filter 0.3s ease calc(var(--d) + 2200ms);
  transition: transform 0.3s ease calc(var(--d) + 2200ms), opacity 0.3s ease calc(var(--d) + 2200ms), filter 0.3s ease calc(var(--d) + 2200ms), -webkit-transform 0.3s ease calc(var(--d) + 2200ms), -webkit-filter 0.3s ease calc(var(--d) + 2200ms);
}

.rocket-button1.live .success svg {
  --o: 0;
  transition: stroke-dashoffset 0.3s ease 2.25s;
}

.rocket-button1.live .animation .rocket {
  -webkit-animation: rocket 2s ease forwards 0.4s;
          animation: rocket 2s ease forwards 0.4s;
}

.rocket-button1.live .animation .rocket:before {
  -webkit-animation: rocket-light 2s ease forwards 0.4s;
          animation: rocket-light 2s ease forwards 0.4s;
}

.rocket-button1.live .animation .smoke i {
  -webkit-animation: smoke 1.7s ease forwards calc(var(--d) + 600ms);
  -webkit-animation: var(--n, smoke) 1.7s ease forwards calc(var(--d) + 600ms);
          animation: smoke 1.7s ease forwards calc(var(--d) + 600ms);
          animation: var(--n, smoke) 1.7s ease forwards calc(var(--d) + 600ms);
}

.rocket-button1.live .animation .smoke i:nth-child(3), .rocket-button1.live .animation .smoke i:nth-child(6) {
  --n: smoke-alt;
}

@-webkit-keyframes pulse {
  50% {
    box-shadow: 0 0 0 2px var(--dot-shadow);
  }
}

@keyframes pulse {
  50% {
    box-shadow: 0 0 0 2px var(--dot-shadow);
  }
}

@-webkit-keyframes pulse1 {
  50% {
    box-shadow: 0 0 0 2px var(--dot-shadow1);
  }
}

@keyframes pulse1 {
  50% {
    box-shadow: 0 0 0 2px var(--dot-shadow1);
  }
}

@-webkit-keyframes pulse2 {
  50% {
    -webkit-filter: drop-shadow(0px 0px 10px rgba(254, 81, 81, 0.3));
            filter: drop-shadow(0px 0px 10px rgba(254, 81, 81, 0.3));
  }
}

@keyframes pulse2 {
  50% {
    -webkit-filter: drop-shadow(0px 0px 10px rgba(254, 81, 81, 0.3));
            filter: drop-shadow(0px 0px 10px rgba(254, 81, 81, 0.3));
  }
}

@-webkit-keyframes shake {
  8%,
  24%,
  40%,
  56%,
  72%,
  88% {
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
  }
  16%,
  32%,
  48%,
  64%,
  80%,
  96% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
}

@keyframes shake {
  8%,
  24%,
  40%,
  56%,
  72%,
  88% {
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
  }
  16%,
  32%,
  48%,
  64%,
  80%,
  96% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
}

@-webkit-keyframes smoke {
  20%,
  80% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  55% {
    -webkit-transform: scale(0.92);
            transform: scale(0.92);
  }
}

@keyframes smoke {
  20%,
  80% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  55% {
    -webkit-transform: scale(0.92);
            transform: scale(0.92);
  }
}

@-webkit-keyframes smoke-alt {
  20%,
  80% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
}

@keyframes smoke-alt {
  20%,
  80% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
}

@-webkit-keyframes rocket {
  35% {
    -webkit-transform: translateY(-56px);
            transform: translateY(-56px);
  }
  80% {
    -webkit-transform: translateY(-48px);
            transform: translateY(-48px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-108px) scale(0.6);
            transform: translateY(-108px) scale(0.6);
    opacity: 0;
  }
}

@keyframes rocket {
  35% {
    -webkit-transform: translateY(-56px);
            transform: translateY(-56px);
  }
  80% {
    -webkit-transform: translateY(-48px);
            transform: translateY(-48px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-108px) scale(0.6);
            transform: translateY(-108px) scale(0.6);
    opacity: 0;
  }
}

@-webkit-keyframes rocket-light {
  35% {
    -webkit-transform: scaleY(0.6);
            transform: scaleY(0.6);
  }
  75% {
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes rocket-light {
  35% {
    -webkit-transform: scaleY(0.6);
            transform: scaleY(0.6);
  }
  75% {
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

.loadingbois {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading1 {
  display: flex;
  font-family: "Poppins-Light";
  color: white;
  justify-content: center;
  cursor: pointer;
  flex: 0.2 1;
}

.heading2 {
  display: flex;
  font-family: "Poppins-Light";
  color: white;
  cursor: pointer;
  flex: 1 1;
  float: left;
  justify-content: flex-end;
}

.heading {
  display: flex;
  font-family: "Poppins-SemiBold";
  color: white;
  cursor: pointer;
  flex: 1 1;
  float: left;
  justify-content: flex-start;
}

.heading span:hover,
.heading1 span:hover,
.heading2 span:hover {
  color: #b7b7b7;
}

.heading,
.heading1,
.heading2 {
  width: auto;
  height: auto;
}

.default,
.default1 {
  font-family: "Poppins-Bold";
}

.Dashboard-Header {
  display: flex;
  width: 100%;
  height: 10vh;
  margin: 0 auto;
  align-items: center;
  background-color: #4a4a4a;
  position: fixed;
  padding-top: 0px;
  justify-content: space-between;
}

.DH {
  display: flex;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  background-color: #4a4a4a;
}

.Dashboard {
  display: flex;
  width: 95%;
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  background-color: white;
  padding-top: 0px;
  justify-content: center;
}

.welcome {
  display: flex;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.welcomebtn,
.welcomebtn2,
.welcometxt {
  margin-left: auto;
  margin-right: auto;
}

.welcometxt {
  font-family: "Poppins-Thin";
  font-size: 50px;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 1483px) {
  .heading1 {
    flex: 0.5 1;
  }
  .welcome {
    height: 500px;
    width: 500px;
  }
}

@media screen and (max-width: 694px) {
  .hamburger {
    display: flex;
    height: auto;
    width: auto;
    cursor: pointer;
    display: flex;
    cursor: pointer;
    flex: 1 1;
    float: left;
    justify-content: flex-end;
  }
  .hamburger img {
    width: 30px;
    cursor: pointer;
    display: flex;
    cursor: pointer;
    flex: 1 1;
    float: left;
    justify-content: flex-start;
  }
  .heading2,
  .heading1 {
    display: none;
  }
  .heading {
    display: flex;
    font-family: "Poppins-Light";
    color: white;
    cursor: pointer;
    flex: 1 1;
    float: left;
    justify-content: flex-start;
  }
  .welcome {
    height: 400px;
    width: 400px;
  }
  .welcometxt {
    font-size: 30px;
  }
}

@media screen and (max-width: 450px) {
  .welcome {
    height: 300px;
    width: 300px;
  }
}

@media screen and (max-width: 240px), (max-height: 320px) {
  .welcome {
    height: 200px;
    width: 200px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .welcometxt {
    font-size: 20px;
  }
  .rocket-button,
  .rocket-button1 {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
}
/*# sourceMappingURL=Dashboard.css.map */
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.titleQR {
  font-size: 50px;
  text-align: center;
}

.QRCode {
  width: 256px;
  height: 256px;
}

@media screen and (max-width: 570px) {
  .titleQR {
    font-size: 40px;
  }

  .QRCode {
    width: 128px;
    height: 128px;
  }

  .sendtxtext {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  .sendbtnbutton {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  .sendwrngwrong {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@media screen and (max-width: 512px) {
  .sendtxtext {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 436px) {
  .titleQR {
    font-size: 30px;
  }
}

@media screen and (max-width: 661px) {
  .QRCODE input {
    width: 100% !important;
  }
}

#CardShareOrForMyself:hover {
  box-shadow: 4px 3px 5px rgb(215, 215, 215);
}

#CardShareOrForMyself {
  transition: ease 0.1s all;
}
.CardShareOrForMyself1 {
  margin-right: 30px;
}

.CARDSHARBTN {
  width: 20%;
}

@media screen and (max-width: 906px) {
  .CARDSHARBTN {
    width: 70%;
  }
}

@media screen and (max-width: 476px) {
  .cardcontainer {
    flex-direction: column;
  }
  .CardShareOrForMyself1 {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.CustomerDashboard h1 {
  text-align: center;
}

.CustomerDashboard input {
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.CustomerDashboard p {
  text-align: center;
}

.QRcodeCard {
  width: 90vw;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
}

.QRcodeCardwrap {
  background-color: #ffe7a7;
  padding: 30px;
  width: 90vw;
  margin: auto;
}

.side1,
.side2 {
  width: 50%;
}

.side2 {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 686px) {
  .side1 {
    margin-bottom: 10px;
    width: 100%;
  }
  .side2 {
    width: 100%;
    flex-direction: column;
  }
  .QRcodeCard {
    flex-direction: column;
    width: 70vw;
  }
  .QRcodeCardwrap {
    width: 70vw;
  }
}

.divider {
  width: 100%;
  height: 1px;
  border: 1px solid black;
}

@media screen and (max-width: 362px) {
  .codeqr {
    width: 200px !important;
    height: 200px !important;
  }
  .CustomerDashboard p input {
    width: 200px !important;
  }
}

