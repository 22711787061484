/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 99999 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.login {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: -2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.titleLog {
  margin-bottom: 50px;
}
.formL {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  margin-top: 10vh;
}

/* fancy Button */
.fancy-button22121,
.fancy-button {
  display: inline-block;
  margin: 30px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

.fancy-button22121,
.ee {
  -webkit-transform: scale(0.61);
  -ms-transform: scale(0.61);
  -moz-transform: scale(0.61);
  -o-transform: scale(0.61);
  transform: scale(0.61);
}
.oo {
  width: unset !important;
}
.fancy-button {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

.fancy-button22121:before,
.fancy-button:before {
  content: "";
  display: inline-block;
  height: 40px;
  position: absolute;
  bottom: -5px;
  left: 30px;
  right: 30px;
  z-index: -1;
  -webkit-border-radius: 30em;
  -moz-border-radius: 30em;
  border-radius: 30em;
  -webkit-filter: blur(20px) brightness(0.95);
  filter: blur(20px) brightness(0.95);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.fancy-button22121 i,
.fancy-button i {
  margin-top: -1px;
  margin-right: 20px;
  font-size: 1.265em;
  vertical-align: middle;
}
.fancy-button22121 span,
.fancy-button span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  padding: 18px 60px;
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  position: relative;
  z-index: 2;
  will-change: transform, filter;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  font-size: x-large;
}
.fancy-button22121:focus,
.fancy-button22121:active,
.fancy-button:focus,
.fancy-button:active {
  color: #fff;
}
.fancy-button22121:hover,
.fancy-button:hover {
  color: #fff;
}
.fancy-button22121:hover span,
.fancy-button:hover span {
  -webkit-filter: brightness(1.05) contrast(1.05);
  filter: brightness(1.05) contrast(1.05);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}
.fancy-button22121:hover:before,
.fancy-button:hover:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
}
.fancy-button22121.pop-onhover:before,
.fancy-button.poponhover:before {
  opacity: 0;
  bottom: 10px;
}
.fancy-button22121.pop-onhover:hover:before,
.fancy-button.poponhover:hover:before {
  bottom: -7px;
  opacity: 1;
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
.fancy-button22121.pop-onhover:hover span,
.fancy-button.poponhover:hover span {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  transform: scale(1.04);
}
.fancy-button22121.pop-onhover:hover:active span,
.fancy-button22121.pop-onhover:hover:active span {
  -webkit-filter: brightness(1) contrast(1);
  filter: brightness(1) contrast(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.fancy-button22121.pop-onhover:hover:active:before,
.fancy-button22121.pop-onhover:hover:active:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");
.buttonb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  cursor: pointer;
  margin: 20px;
}
.buttonb svg {
  height: 55px;
  width: 150px;
  fill: none;
  stroke-width: 5;
}
.buttonb svg .o1 {
  stroke: rgba(255, 179, 65, 0.7);
  fill: #ffa114;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  width: 150%;
}
.buttonb svg .o2 {
  stroke: white;
  stroke-dasharray: 20 420;
  stroke-dashoffset: 20;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.buttonb svg text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  bottom: 0;
  margin: auto 0;
  text-transform: uppercase;
  letter-spacing: 3px;
  z-index: 99090909;
}
text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}

.buttonb:hover .o1 {
  fill: rgba(204, 129, 16, 0.3);
}
.buttonb:hover .o2 {
  stroke-dashoffset: -420;
}
.loginB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
}

.spanbois2 {
  margin-right: 30px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.spanbois {
  margin-left: 30px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@media screen and (max-width: 658px) {
  .formL {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@media screen and (max-width: 504px) {
  .formL {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@media screen and (max-width: 374px) {
  .formL {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  .titleLog {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    margin: 0;
  }
}

@media screen and (max-width: 298px) {
  .formL {
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -o-transform: scale(0.6);
    transform: scale(0.6);
  }
  .titleLog {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    margin: 0;
  }
}
