.mapMapBox {
  width: 68vw;
  height: 87vh;
  margin-left: 31vw;
  position: fixed;
  margin-top: 13vh;
}

.MediaCard {
  float: left;
}

.gridlist {
  width: 25vw;
  float: left;
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: static !important;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-top: 10vh;
}

.shopwrapperwrappy {
  height: 100vh;
  width: 100vw;
}

.search {
  /* margin-left: 30px;
  margin-right: 30px; */
  /* position: fixed; */
  background-color: rgb(74, 74, 74) !important;
  z-index: 1;
  padding: 30px;
  top: 10vh;
  border-radius: 0px 0px 6px 6px;
}

.khdsaid12e {
  z-index: 2 !important;
}

.Search_Businesses {
  color: white;
  font-weight: bold;
}

.Search_Businesses a {
  display: none;
  color: white;
}
/* 
@media screen and (max-width: 1518px) {
  .mapMapBox {
    width: 58vw;
    margin-left: 41vw;
  }
  .gridlist {
    width: 40vw;
  }
  .search {
    width: 25vw;
    margin: auto;
  }
}

@media screen and (max-width: 1224px) {
  .mapMapBox {
    width: 48vw;
    margin-left: 51vw;
  }
  .gridlist {
    width: 50vw;
  }
  .search {
    width: 25vw;
    margin: auto;
  }
}
@media screen and (max-width: 953px) {
  #cardWrapper {
    width: 80% !important;
  }
}

@media screen and (max-width: 832px) {
  .mapMapBox {
    display: none !important;
  }
  #cardWrapper {
    width: 90% !important;
  }
  .gridlist {
    width: 100vw;
  }
  .Search_Businesses a {
    display: flex;
    width: fit-content;
  }
  .search {
    width: 92vw;
    left: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 442px) {
  #cardWrapper {
    width: 80% !important;
  }
} */

@media screen and (max-width: 1605px) {
  .mapMapBox {
    width: 58vw;
    margin-left: 41vw;
  }
  .gridlist {
    width: 35vw;
  }
}

@media screen and (max-width: 1151px) {
  .mapMapBox {
    width: 48vw;
    margin-left: 51vw;
  }
  .gridlist {
    width: 45vw;
  }
}

@media screen and (max-width: 889px) {
  .mapMapBox {
    display: none;
  }
  .gridlist {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 463px) {
  .gridlist {
    width: calc(100% - 40px);
    margin-left: 20px;
  }
}

@media screen and (max-width: 437px) {
  .search-main {
    flex-direction: column !important;
  }
  .search-main input {
    border-radius: 6px !important;
  }
  .searchSubmit {
    width: unset !important;
  }
}
